import { AppProps } from 'next/app'
import '@/styles/globals.scss'
import { useCookies } from '@/app/hooks/useCookies'
import Head from 'next/head'
import SetCookiesOnFirstVisit from '@/app/utils/SetCookiesOnFirstVisit'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import { AuthProvider } from '@/app/contexts/AuthContext'
import Header from '@/app/components/UI/Header'
import Footer from '@/app/components/UI/Footer'
import HeaderCMS from '@/app/components/UI/HeaderCMS'
import dynamic from 'next/dynamic'
import { ThemeProvider } from '@/app/contexts/ThemeContext'
import useGoogleTagManager from '@/app/hooks/useGoogleTagManager'
import CookieConsent from '@/app/components/UI/CookieConsent'
import FormSentModal from '@/app/components/UI/FormSentModal'
import Chatbot from '@/app/components/UI/Chatbot'
import ChatbotIcon from '@/app/components/UI/ChatbotIcon'
import { vehiclesBYD } from '@/app/utils/vehiclesBYD'
import { BannerProvider } from '@/app/contexts/BannerContext' // Adicione o BannerProvider

const DynamicAlertModal = dynamic(() => import('@/app/components/UI/AlertModal'), { ssr: false })
const DynamicConditionsModal = dynamic(() => import('@/app/components/UI/ConditionsModal'), { ssr: false })

const cmsPath = '/cms'
const specialPaths = ['/checkin', 'checkout', '/folder', '/pesquisa', '/meu-byd', '/duvidas', '/contato-pos-venda', cmsPath]

const needsStandardLayout = (path: string): boolean => {
  return !specialPaths.some(specialPath => path === specialPath || path.includes(specialPath))
}

const App = ({ Component, pageProps }: AppProps) => {
  const { cookiePreferences, handleConsent, hasCookie } = useCookies()
  const router = useRouter()
  const { asPath } = router
  const [newBanner, setNewBanner] = useState<boolean>(false)
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const [isConditionsModalOpen, setIsConditionsModalOpen] = useState(false)
  const [conditionsContent, setConditionsContent] = useState<string>('')
  const [isFormSentModalOpen, setIsFormSentModalOpen] = useState(false)
  const [isChatbotOpen, setChatbotOpen] = useState(false)

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // Isso garante que o código só roda no cliente
    setIsClient(true)
  }, [])

  // Ordena os veículos por family e depois por order_family
  const sortedVehicles = vehiclesBYD.sort((a, b) => {
    if (a.family === b.family) {
      return a.order_family - b.order_family
    }
    return a.family - b.family
  })

  // Agora faz o reduce nos veículos ordenados
  const vehicles = sortedVehicles.reduce((acc: any, element: any) => {
    if (element.new) {
      acc.news.push(element)
    }
    
    if (element.model === 'eletric') {
      acc.eletrics.push(element)
    }
    
    if (element.model === 'hybrid') {
      acc.hybrids.push(element)
    }

    return acc
  }, {
    news: [], eletrics: [], hybrids: []
  })

  // Dados que serão compartilhados pelo BannerProvider
  const bannerData = {
    contents: pageProps.contents || [],
    defenseTypes: pageProps.defenseTypes || []
  }

  const handleAccept = useCallback(() => {
    handleConsent({ necessary: true, analytics: true, advertising: true, performance: true })
  }, [handleConsent])

  const handleDecline = useCallback(() => {
    handleConsent({ necessary: true, analytics: false, advertising: false, performance: false })
  }, [handleConsent])

  useGoogleTagManager()

  useEffect(() => {
    if (localStorage.getItem('showModalAfterReload') === 'true') {
        setIsFormSentModalOpen(true)
        setTimeout(() => {
            localStorage.removeItem('showModalAfterReload')
        }, 100)
    }
  }, [])

  return (
    <>
      <Head>
        <link rel="icon" href={'/img/favicon.ico'} />
      </Head>
      {needsStandardLayout(asPath) ? (
        <ThemeProvider>
          <BannerProvider {...bannerData}> {/* BannerProvider envolvendo o layout */}
            <Header setIsAlertModalOpen={setIsAlertModalOpen} vehicles={vehicles} />
            <SetCookiesOnFirstVisit />
            <Component {...pageProps} cookiePreferences={cookiePreferences} setChatbotOpen={setChatbotOpen} setIsConditionsModalOpen={setIsConditionsModalOpen} setConditionsContent={setConditionsContent} />
            <CookieConsent
              onAccept={handleAccept}
              onDecline={handleDecline}
              buttonText="Aceitar todos os cookies"
              declineButtonText="Apenas os necessários"
              text='Ao clicar em "Aceitar todos os cookies", concorda com o armazenamento de cookies no seu dispositivo para melhorar a navegação e analisar a utilização do site, além de ajudar nas nossas iniciativas de marketing.'
              cookieURL='/cookies'
              hasCookie={hasCookie}
            />
            {isAlertModalOpen && <DynamicAlertModal setIsAlertModalOpen={setIsAlertModalOpen} />}
            {isConditionsModalOpen && <DynamicConditionsModal setIsConditionsModalOpen={setIsConditionsModalOpen} content={conditionsContent} />}
            {isFormSentModalOpen && <FormSentModal setIsFormSentModalOpen={setIsFormSentModalOpen} />}
            <ChatbotIcon isChatbotOpen={isChatbotOpen} setChatbotOpen={setChatbotOpen} />
            <Chatbot isOpen={isChatbotOpen} setChatbotOpen={setChatbotOpen} />
            <Footer /> {/* Footer pode acessar os dados do BannerContext */}
          </BannerProvider>
        </ThemeProvider>
      ) : asPath.includes(cmsPath) ? (
        <AuthProvider>
          {/* Renderiza o HeaderCMS somente quando estamos no cliente */}
          {isClient && router.pathname !== cmsPath && window?.location?.hostname !== 'vitoriamotorsbyd.com.br' && (
            <HeaderCMS setNewBanner={setNewBanner} />
          )}
          <Component newBanner={newBanner} setNewBanner={setNewBanner} {...pageProps} />
        </AuthProvider>
      ) : (
        <ThemeProvider>
          <Component {...pageProps} cookiePreferences={cookiePreferences} />
          <CookieConsent
            onAccept={handleAccept}
            onDecline={handleDecline}
            buttonText="Aceitar todos os cookies"
            declineButtonText="Apenas os necessários"
            text='Ao clicar em "Aceitar todos os cookies", concorda com o armazenamento de cookies no seu dispositivo para melhorar a navegação e analisar a utilização do site, além de ajudar nas nossas iniciativas de marketing.'
            cookieURL='/cookies'
            hasCookie={hasCookie}
          />
        </ThemeProvider>
      )}
    </>
  )
}

export default App
