// Dados exportados da tabela vehiclesBYD

export const vehiclesBYD = [
  {
  model: 'eletric',
  name: 'dolphin mini',
  new: false,
  soon: false,
  family: 1,
  order_family: 1,
  qty_imgs: 9,
  title_intro: 'BYD DOLPHIN MINI',
  text_intro: 'SEU 1º CARRO ELÉTRICO',
  titles_info: ['405km', '0,41 MJ/km', 'Segurança', 'Agilidade'],
  texts_info: ['Autonomia CLTC e 280km de autonomia PBEV', 'Maior eficiência energética do Brasil', '6 air-bags e freio a disco nas quatro rodas', 'Carregamento Fácil'],
  title_overview: 'CONHEÇA O NOVO MEMBRO DA FAMÍLIA DOLPHIN',
  text_overview: 'Para além de um carro elétrico, o BYD Dolphin Mini é a representação da popularização da tecnologia automotiva no Brasil, tornando-a acessível e atraente.',
  title_design: 'ECONOMIA E COMODIDADE',
  text_design: 'Redução nos custos com combustível e baixa manutenção são os componentes que promovem a economia no Dolphin Mini, transformando esse compacto na melhor escolha para quem busca eficiência financeira e ecológica.',
  titles_feature: ['SEGURANÇA', 'TECLAS DE CONTROLE'],
  texts_feature: ['Apesar de “mini” no tamanho, esse 100% elétrico se destaca quando o assunto é segurança. Além de 6 airbags, o BYD Dolphin Mini conta com freios a disco nas 4 rodas e uma estrutura de aço por todo o veículo. Tais recursos garantem não apenas uma condução mais segura, mas também maior resistência em caso de colisões.', 'O console possui design ergonômico que proporciona uma experiência ágil e confortável, além de uma usabilidade intuitiva.'],
  titles_detail: ['6 AIRBAGS', 'FREIO A DISCO NAS 4 RODAS', 'CARREGADOR POR INDUÇÃO', '61% DE AÇO NA ESTRUTURA'],
  texts_detail: ['O BYD Dolphin Mini possui 6 airbags no total, sendo 2 frontais, 2 laterais e 2 de cortina.', 'Possui freio a disco em todas as rodas proporcionando mais segurança nas frenagens e funcionalidade como fator regenerativo para a bateria.', 'O BYD Dolphin Mini possui um espaço para carregamento sem fio, além de uma entrada USB tipo A e outra tipo C.', 'Exemplo de segurança, o BYD Dolphin Mini possui mais da metade de sua estrutura composta por aço, trazendo mais segurança no caso de colisões.'],
  title_penultimate: 'e-Platform 3.0',
  text_penultimate: 'O Dolphin Mini também usa a e-Platform 3.0, especialmente desenvolvida pela BYD para veículos puramente elétricos. A plataforma busca melhorar a segurança e a autonomia, bem como garantir uma experiência de condução inteligente, criando um veículo mais eficiente e seguro.',
  titles_color: ['Sprout Green', 'Polar Night Black', 'Peach Pink', 'Apricity White'],
  hexs_color: ['conic-gradient(from 249deg at 88.8% 29.26%, #F0F2B2 0deg, #D9DE71 30.600000321865082deg, #A0AA38 323.99999141693115deg, #9BA634 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #B8BDC3 0deg, #383D43 30.600000321865082deg, #23262B 323.99999141693115deg, #0C0D0F 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #FBF1E7 0deg, #EEE0D7 30.600000321865082deg, #D9C9BF 323.99999141693115deg, #A79591 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #FDFAF1 0deg, #EEEBE2 30.600000321865082deg, #E1DED5 323.99999141693115deg, #B3B0A7 360deg)']
  },
  {
  model: 'eletric',
  name: 'dolphin plus',
  new: false,
  soon: false,
  family: 1,
  order_family: 3,
  qty_imgs: 6,
  title_intro: 'BYD Dolphin Plus',
  text_intro: '100% ELÉTRICO',
  titles_info: ['204cv', '310 N.m', '330 km', 'Linha Ocean'],
  texts_info: ['Potência', 'Torque', 'Autonomia PBEV', 'Inspirada nos animais marinhos e nos oceanos'],
  title_overview: 'DESIGN OCEÂNICO',
  text_overview: 'O BYD Dolphin Plus segue as inspirações e conceitos que fundamentam o design da linha “Ocean”. Inspirada nos animais marinhos e nos oceanos, o Dolphin Plus realça os contornos sofisticados da carroceria, alinhados a um conjunto de tecnologias e técnicas que, combinados com as formas fluidas do veículo, projetam um design confiante, elegante e libertador.',
  title_design: 'TETO PANORÂMICO',
  text_design: 'Cobrindo a superfície superior da cabine, o teto panorâmico oferece visibilidade e versatilidade, permitindo entrada de luz no interior do veículo.',
  titles_feature: ['TECNOLOGIAS DE ALTA EFICIÊNCIA', 'COMPETENTEMENTE PRODUTIVO', 'SEGURANÇA NOS MÍNIMOS DETALHES'],
  texts_feature: ['A BYD oferece, como parte intrínseca de seus veículos, recursos extraordinários de integração e tecnologia. O Dolphin Plus é equipado com a mais recente e revolucionária Bateria Blade que passou por uma série de testes extremos em condições rigorosas, tornando-a uma das mais seguras do mundo.', 'A BYD produz seus carros seguindo uma competente linha de tecnologias integradas de forma a entregar eficiência e performance nos mais variados ambientes. Por exemplo, essa é a primeira vez que um trem de força elétrico é produzido utilizando oito componentes em um único elemento para os veículos. Todo o esforço para oferecer praticidade e flexibilidade a um veículo 100% elétrico.', 'Demonstrando nosso compromisso com a segurança na construção dos veículos, implementamos um sistema interno inovador, no qual a bomba de calor eficientemente remove o calor residual de componentes internos, elevando a eficiência térmica e reduzindo as perdas de energia tanto no aquecimento quanto no resfriamento.'],
  titles_detail: [],
  texts_detail: [],
  title_penultimate: 'e-Platform 3.0',
  text_penultimate: 'O Dolphin Plus também usa a e-Platform 3.0, especialmente desenvolvida pela BYD para veículos puramente elétricos. A plataforma busca melhorar a segurança e a autonomia, bem como garantir uma experiência de condução inteligente, criando um veículo mais eficiente e seguro.',
  titles_color: ['Surfing Blue + Dolphin Grey', 'Ski White + Dolphin Grey', 'Delan Black + Atlantis Grey', 'Afterglow Pink + Dolphin Grey'],
  hexs_color: ['conic-gradient(from 249deg at 88.8% 29.26%, #323337 0deg, #7A7D7E 18.0000002682209deg, #207CA9 18.359999656677246deg, #095B88 80.99999785423279deg, #044670 323.99999141693115deg, #032E51 352.8000068664551deg, #D4D6D6 352.8000068664551deg, #7E8185 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #323337 0deg, #7A7D7E 18.0000002682209deg, #DADBE0 18.359999656677246deg, #C3C6CF 80.99999785423279deg, #BABDC9 323.99999141693115deg, #89939F 352.8000068664551deg, #D4D6D6 352.8000068664551deg, #7E8185 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #323337 0deg, #7A7D7E 18.0000002682209deg, #6C809D 18.359999656677246deg, #43536C 80.99999785423279deg, #3A4A63 323.99999141693115deg, #253040 352.64482498168945deg, #3F4145 352.8000068664551deg, #0C0E10 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #323337 0deg, #7A7D7E 18.0000002682209deg, #CBAEBA 18.359999656677246deg, #BB99A7 80.99999785423279deg, #997785 323.99999141693115deg, #7E606C 352.8000068664551deg, #D4D6D6 352.8000068664551deg, #7E8185 360deg)']
  },
  {
  model: 'eletric',
  name: 'dolphin',
  new: false,
  soon: false,
  family: 1,
  order_family: 2,
  qty_imgs: 6,
  title_intro: 'BYD DOLPHIN',
  text_intro: '100% ELÉTRICO',
  titles_info: ['Carga Rápida', '241 km', 'e-Platform 3.0', 'Linha Ocean'],
  texts_info: ['30% a 80% em 30 minutos', 'Autonomia (PBEV)', 'Eficiência, inteligência, segurança e estética', 'Inspirada nos animais marinhos e no oceano'],
  title_overview: 'DESIGN OCEÂNICO',
  text_overview: 'O Dolphin é o primeiro veículo da BYD Brasil a adotar o design da linha “Ocean” inspirada nos animais marinhos e oceano. O contorno sofisticado da carroceria, com linhas retas e formas fluidas, projeta um design confiante e elegante.',
  title_design: 'AS LUZES',
  text_design: 'O design das luzes dianteiras e traseiras são inspiradas no movimento dinâmico dos golfinhos saindo do oceano. O farol contínuo de LED atravessa a grade dianteira trazendo um estilo moderno e tecnológico.',
  titles_feature: ['TECNOLOGIAs ASSISTENTES'],
  texts_feature: ['Mais do que apenas um carro, os veículos elétricos BYD também fornecem energia para aparelhos em uso externo, para o preparo de alimentos, iluminação, cinema ao ar livre, refrigeração, aquecimento, carregamento de emergência, entre outras possibilidades. Ative o Controle de Voz falando "Hi BYD" e realize funções como ligar o ar condicionado e aumentar o volume da música. A luz traseira é em LED penetrante, ecoando os faróis dianteiros. O processo geométrico de moldagem por injeção integra as peças decorativas, aumentando e texturizando o painel de luz.'],
  titles_detail: ['TELA TOUCHSCREEN ROTATIVA', 'TECLAS DE CONTROLE', 'Espaço confortável para os passageiros do banco traseiro', 'Maçanetas semelhantes às nadadeiras de um golfinho', 'Assentos com tecidos ventilados'],
  texts_detail: ['A grande tela touchscreen de 12,8 polegadas está equipada com o nosso sistema ICS (Intelligent Cockpit System) proporcionando um universo conectado, integrando com comando de voz as funções do carro. O motorista pode dirigir sem distrações, ao mesmo tempo em que adiciona entretenimento inteligente à sua experiência.', 'O design ergonômico proporciona uma experiência ágil e confortável.', 'Graças a e-Platform 3.0, ao balanço dianteiro e traseiro curtos e a longa distância entre eixos, o BYD Dolphin tem uma aparência harmônica e espaçosa. O piso plano do compartimento traseiro oferece mais conforto e espaço para o passageiro do banco do meio.', 'Inspirada nas nadadeiras de golfinhos, cheia de vitalidade e energia. Quando os passageiros abrem a porta, podem sentir como se estivessem dando as mãos para um golfinho.', 'Os assentos são feitos de couro vegano sustentável, oferecendo uma solução ecologicamente correta sem comprometer o estilo. Com os pequenos orifícios de ventilação, você pode desfrutar muito melhor do seu assento em uma viagem longa.'],
  title_penultimate: 'e-Platform 3.0',
  text_penultimate: 'O Dolphin também usa a e-Platform 3.0, especialmente desenvolvida pela BYD para veículos puramente elétricos. A plataforma busca melhorar a segurança e a autonomia, bem como garantir uma experiência de condução inteligente, criando um veículo mais eficiente e seguro.',
  titles_color: ['Dolphin Grey', 'Cheese Yellow', 'Afterglow Pink'],
  hexs_color: ['conic-gradient(from 249deg at 88.8% 29.26%, #B9B9B7 0deg, #ADAFAF 23.399999141693115deg, #646466 270deg, #3F3F3F 349.2000102996826deg, #BF2E1F 349.2000102996826deg, #F1816B 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #E8E5D7 0deg, #EDEFE4 28.799999356269836deg, #CCC9B6 300.59999227523804deg, #BBB6A7 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #D8CEC8 0deg, #D0C1BF 19.79999989271164deg, #B6A59E 300.59999227523804deg, #806E68 360deg)']
  },
  {
  model: 'eletric',
  name: 'seal',
  new: false,
  soon: false,
  family: 5,
  order_family: 1,
  qty_imgs: 16,
  title_intro: 'BYD SEAL',
  text_intro: '100% ELÉTRICO',
  titles_info: ['3.8s', '372 km', 'CTB', 'Linha Ocean'],
  texts_info: ['0-100 km/h', 'Autonomia (PBEV)', 'Tecnologia Cell to Body', 'Inspirada nos animais marinhos e no oceano'],
  title_overview: 'OCEAN X FACE',
  text_overview: 'A frente em forma de X do BYD Seal é uma declaração ousada de seu design premiado e espírito inovador.',
  title_design: 'DESIGN ESPORTIVO "GOLDEN RATIO".',
  text_design: 'As linhas ágeis e expressivas do corpo do BYD Seal são complementadas por um perfil lateral suave e natural. A perfeita relação eixo/comprimento de 0.61, alcançada com um comprimento de carroceria de 4800mm e uma distância entre eixos de 2.920mm, desenha um estilo único para o sedã esportivo.',
  titles_feature: ['TECNOLOGIA CTB', 'BATERIA BLADE'],
  texts_feature: ['O BYD Seal é o primeiro modelo equipado com a inovadora tecnologia CTB (Cell to Body) que integra a Bateria Blade à carroceria do carro, formando uma forte estrutura em “sanduíche”, proporcionando uma notável rigidez torcional de 40.500 Nm/°. Com a tecnologia CTB, a Bateria Blade é mais que uma fonte de energia, é também um componente estrutural capaz de suportar forças significativas.', 'A BYD é pioneira na indústria de baterias há mais de 28 anos. A mais recente e revolucionária Bateria Blade passou por uma série de testes extremos em condições rigorosas, tornando-a uma das mais seguras do mundo.'],
  titles_detail: ['INTERIOR SOFISTICADO DE ALTA QUALIDADE', 'RODA BLADE PRECISION(AWD)', 'SISTEMA DE COCKPIT INTELIGENTE BYD', 'DYNAUDIO PERSONALIZADO CLASSE-HIFI'],
  texts_detail: ['O interior do BYD Seal é decorado com acolchoamento em forma de diamante e tecnologia de costura dupla, o que aumenta ainda mais a sensação de alta qualidade e refinamento do interior. Além disso, o tratamento perfurado no tecido interior não só tem melhor permeabilidade ao ar, mas também a disposição das gotas de água nos poros é uma boa resposta ao tema oceânico.', 'O BYD Seal oferece dois estilos diferentes de rodas. A roda de 18 polegadas apresenta pequenas formas triangulares, que acompanham seu design vivo e enérgico. A Roda Blade de 19 polegadas possui um design simples, mas capaz. Sua superfície fechada é decorada com listras que formam um efeito visual deslumbrante quando vistas de diferentes ângulos. A requintada estrutura guia de palhetas reduz efetivamente a resistência do vento.', 'O BYD Seal está equipado com uma tela sensível ao toque rotativa de 15,6 polegadas com resolução de alta definição de 1920 x 1080. A exibição em tela dividida permite que você aproveite a conveniência de dirigir e se divertir simultaneamente.', 'O sistema de som surround Dynaudio do BYD Seal é pioneiro na indústria, com 12 alto-falantes que fornecem sonoridade envolvente aos passageiros em todos os assentos. O sistema tem potência máxima de saída de 775W e alta fidelidade, tornando cada viagem uma experiência musical.'],
  title_penultimate: 'e-Platform 3.0',
  text_penultimate: 'O Seal também usa a e-Platform 3.0, especialmente desenvolvida pela BYD para veículos puramente elétricos. A plataforma busca melhorar a segurança e a autonomia, bem como garantir uma experiência de condução inteligente, criando um veículo mais eficiente e seguro.',
  titles_color: ['Bright White', 'Atlantis Grey', 'Courtyard Green', 'Cosmos Black', 'Rosemary Grey', 'Glacier Blue'],
  hexs_color: ['conic-gradient(from 249deg at 88.8% 29.26%, #FFF 0deg, #E8EAEE 30.600000321865082deg, #E5E7E9 323.99999141693115deg, #D2D5D7 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #D0DCE4 0deg, #778FA2 28.799999356269836deg, #415769 300.59999227523804deg, #304454 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #B3B8B5 0deg, #76817B 19.79999989271164deg, #505652 300.59999227523804deg, #323834 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #53575B 0deg, #25292F 23.399999141693115deg, #12171A 309.1741132736206deg, #07080B 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #E9EDEE 0deg, #BCBFC4 28.799999356269836deg, #979CA0 300.59999227523804deg, #6B7074 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #DEE4EB 0deg, #AEBBCA 19.79999989271164deg, #6B859C 300.59999227523804deg, #405A6F 360deg)']
  },
  {
  model: 'eletric',
  name: 'yuan plus',
  new: false,
  soon: false,
  family: 4,
  order_family: 2,
  qty_imgs: 8,
  title_intro: 'BYD YUAN PLUS',
  text_intro: '100% ELÉTRICO',
  titles_info: ['204cv', '7.3s', '294 km', '312 Litros'],
  texts_info: ['Potência', '0-100 km/h', 'Autonomia PBEV', 'Porta-malas'],
  title_overview: 'VISUAL ARROJADO PARA O SEU ESTILO DE VIDA',
  text_overview: 'Ao longo da expressiva linha de cintura ascendente e das arrojadas rodas de liga leve,o BYD Yuan Plus oferece um design esportivo e aerodinâmico.',
  title_design: 'BYD YUAN PLUS: PARA VER O FUTURO.',
  text_design: 'Os faróis, que harmonizam com as luzes traseiras contínuas, têm um feixe mais amplo, que melhora muito a visibilidade e a experiência de dirigir à noite. Em conjunto com a luz de direção dinâmica, o BYD Yuan Plus mantém você mais seguro.',
  titles_feature: ['RECARGA VELOZ', 'FIQUE CONECTADO'],
  texts_feature: ['É possível carregar rapidamente, utilizando uma capacidade de carga de DC 88kW. Você pode carregar seu BYD Yuan Plus EV de 30% a 80% em apenas 29 minutos.', 'Um cockpit de conectividades enriquece o seu tempo de condução. A tela touchscreen de 12,8 polegadas pode ser rotacionada com um simples toque dos dedos para você controlar as principais funcionalidades do carro.'],
  titles_detail: ['SENSORES', 'COMBINANDO ELEGÂNCIA E SOFISTICAÇÃO', 'PORTAS ÚNICAS', 'TECNOLOGIAS ASSISTENTES'],
  texts_detail: ['O BYD YUAN PLUS EV é equipado com sensores dianteiros e traseiros que auxiliam o motorista durante toda a condução. A câmera 360° amplia a visão e oferece ainda mais segurança aos passageiros.', 'As surpresas do design estão por todos os lados. As cores vêm da natureza e a vida regressa a ela, fazendo com que as pessoas se sintam calmas, elegantes e leves. Tudo contribuí para uma atmosfera rítmica leve. As cores mudam de acordo com as variações da música. Quem vive a experiência, sente-se jovem e com energia o tempo todo.', 'Portas com desenho exclusivo e original com 2 dos 8 alto-falantes do Yuan Plus que se traduzem em uma incrível qualidade de som.', 'A BYD tem uma capacidade excepcional de integração vertical dentro da cadeia de fornecimento. A BYD integra 8 componentes-chave incorporando VCU, BMS, MCU, PDU, controlador DC-DC, carregador de bordo, motor de acionamento e transmissão, produzindo o primeiro sistema 8 em 1 de produção em massa do mundo, otimizando grandemente a utilização do espaço e a eficiência energética.'],
  title_penultimate: 'e-Platform 3.0',
  text_penultimate: 'O Yuan Plus também usa a e-Platform 3.0, especialmente desenvolvida pela BYD para veículos puramente elétricos. A plataforma busca melhorar a segurança e a autonomia, bem como garantir uma experiência de condução inteligente, criando um veículo mais eficiente e seguro.',
  titles_color: ['Surfing Blue', 'Adventuring Green', 'Skiing White', 'Climbing Grey'],
  hexs_color: ['conic-gradient(from 249deg at 88.8% 29.26%, #04AECF 0deg, #007CA7 30.600000321865082deg, #00516E 323.99999141693115deg, #002F3D 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #A9B4A4 0deg, #7C8973 30.600000321865082deg, #65715D 323.99999141693115deg, #444D3C 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #F2F2F4 0deg, #D5D6DB 30.600000321865082deg, #CDD0D4 323.99999141693115deg, #B8BDC3 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #A1A6B1 0deg, #7F8591 32.40000128746033deg, #6B727C 323.99999141693115deg, #363940 360deg)']
  },
  {
  model: 'eletric',
  name: 'tan 2025',
  new: false,
  soon: false,
  family: 7,
  order_family: 2,
  qty_imgs: 13,
  title_intro: 'BYD TAN',
  text_intro: '100% ELÉTRICO',
  titles_info: ['4.9s', '430 km', 'Suspensão Eletrônica', 'SUV de 7 lugares'],
  texts_info: ['0-100 km/h', 'Autonomia', 'Semi-ativa DiSus-C', 'Espaço Interno'],
  title_overview: 'Conforto, Elegância e Performance',
  text_overview: 'Um SUV de 7 lugares, com capacidade de entregar a versatilidade de um aventureiro, sem abrir mão do conforto, linhas elegantes, e toda a esportividade de um carro 100% elétrico.',
  title_design: '0 a 100 em 4,9 segundos',
  text_design: '517 cv de potência e 700 N.m de torque direto, propiciam uma aceleração de 0 a 100Km/h em menos de 5 segundos.',
  titles_feature: ['SEGURANÇA', 'Bateria Blade da BYD, segurança classe mundial'],
  texts_feature: ['O BYD Tan EV conta com um altíssimo padrão de segurança, para acompanhar toda a performance que o carro é capaz de entregar e garantir máxima proteção à sua família.', 'Com 108,8 kWh, estabelece um novo padrão de segurança e performance para veículos elétricos. O BYD Tan pode ser recarregado de 30% a 80% em apenas 30 minutos em corrente contínua de 110 kW. A Bateria Blade do Tan tem 8 anos de garantia.'],
  titles_detail: ['Espaço interno', 'Alto falante Dynaudio', 'Frenagem de emergência automática', 'Tela flutuante rotativa de 15,6“'],
  texts_detail: ['Porta-malas de 1655 litros (com os bancos rebaixados), 940 litros (com 5 lugares, e 235 litros (com 7 lugares).', 'Sistema de som completo, equipado com 12 alto falantes Dynaudio com output de 775W.', 'Sistema ADA de auxílio à condução, aumentando a segurança e evitando acidentes.', 'Sistema de navegação integrado e diversos aplicativos de origem, além da possibilidade de espelhar o celular.'],
  title_penultimate: 'e-Platform 3.0',
  text_penultimate: 'O Tan também usa a e-Platform 3.0, especialmente desenvolvida pela BYD para veículos puramente elétricos. A plataforma busca melhorar a segurança e a autonomia, bem como garantir uma experiência de condução inteligente, criando um veículo mais eficiente e seguro.',
  titles_color: ['Doctor Black', 'Time Grey', 'Snow White'],
  hexs_color: ['conic-gradient(from 249deg at 88.8% 29.26%, #949494 0deg, #111 30.600000321865082deg, #2A2A2A 323.99999141693115deg, #000 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #95999D 0deg, #71787E 28.799999356269836deg, #424549 300.59999227523804deg, #212325 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #EDEDED 0deg, #D4D4D4 23.399999141693115deg, #BFBFBF 309.1741132736206deg, #9E9E9E 360deg)']
  },
  {
  model: 'eletric',
  name: 'han',
  new: false,
  soon: false,
  family: 8,
  order_family: 1,
  qty_imgs: 8,
  title_intro: 'BYD HAN',
  text_intro: '100% ELÉTRICO',
  titles_info: ['186 km/h', '494 cv', '32.8 m', '680 N.m'],
  texts_info: ['Velocidade Máxima', 'Potência', 'Distância de Frenagem', 'Torque'],
  title_overview: 'A MELHOR EXPERIÊNCIA AWD',
  text_overview: 'O BYD Han leva você a uma emocionante viagem, com aceleração de 0-100 km/h em apenas 3,9s. A tração elétrica integral funciona em tempo real e proporciona um excelente controle de tração em todas as condições.',
  title_design: 'EQUILÍBRIO PERFEITO ENTRE ARTE E TECNOLOGIA',
  text_design: 'Cada curva do BYD Han é cuidadosamente trabalhada com precisão cirúrgica. O coeficiente aerodinâmico de apenas 0.233, suas formas arrojadas e design criativo geram ótima eficiência, que se traduz em economia energética. Em 2021 o modelo foi vencedor do consagrado prêmio alemão "IF DESIGN".',
  titles_feature: ['A melhor experiência AWD', 'Equilíbrio perfeito entre arte e tecnologia'],
  texts_feature: ['O BYD Han EV leva você a uma emocionante viagem, com aceleração de 0-100 km/h em apenas 3,9s. A tração elétrica integral funciona em tempo real e proporciona excelente controle de tração em todas as condições. Explore o seu mundo, com muita potência.', 'Cada curva do BYD Han EV é cuidadosamente trabalhada com precisão cirúrgica. O coeficiente aerodinâmico de apenas 0.233, suas formas arrojadas e design criativo geram ótima eficiência, que se traduz em economia energética.'],
  titles_detail: ['Faróis de LED com design Dragon Face', 'Maçanetas inteligentes', 'Rodas Esportivas Dinâmicas', 'Lanterna Traseira em LED Sequencial'],
  texts_detail: ['Os belos e amplos faróis de LED adicionam uma sensação personalizada de elegância à sua experiência na estrada.', 'Perfeitamente integrada ao design lateral, a maçaneta embutida é acionada automaticamente, quando o motorista se aproxima do carro e está pronta para abrir. Melhora o fluxo de ar acima do corpo lateral, reduz efetivamente a corrente de redemoinho lateral e minimiza o coeficiente de arrasto.', 'Rodas de liga de alumínio de 19 polegadas com pinças de freios Brembo, padrão de carros de corrida.', 'Inspirada na estética asiática, a luz traseira fluida foi projetada para ser atraente, misteriosa e reconhecível.'],
  title_penultimate: null,
  text_penultimate: null,
  titles_color: ['AI Blue', 'Space Black', 'Time Grey', 'Snow White'],
  hexs_color: ['conic-gradient(from 249deg at 88.8% 29.26%, #A3CCF1 0deg, #1990D2 30.600000321865082deg, #044CA1 323.99999141693115deg, #0A1737 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #949494 0deg, #111 30.600000321865082deg, #2A2A2A 323.99999141693115deg, #000 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #DEDEDE 0deg, #A3A3A3 32.40000128746033deg, #6A6A6A 323.99999141693115deg, #454545 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #EDEDED 0deg, #D4D4D4 23.399999141693115deg, #BFBFBF 309.1741132736206deg, #9E9E9E 360deg)']
  },
  {
  model: 'hybrid',
  name: 'song pro',
  new: true,
  soon: false,
  family: 3,
  order_family: 1,
  qty_imgs: 16,
  title_intro: 'BYD SONG PRO',
  text_intro: 'PERFEITO PARA CIDADE E PARA SAIR DELA',
  titles_info: ['Até 1.100 Km', 'Até 235 cv', 'Bateria Blade (LFP)', 'DM-i GS/GL'],
  texts_info: ['Autonomia combinada (Híbrido Plug-in)', 'Potência Combinada', 'Tipo de Bateria', 'Híbrido Plug-in'],
  title_overview: 'Espaço amplo para cinco passageiros',
  text_overview: 'O veículo possui uma aparência mais larga, conferindo uma presença visual imponente, um SUV espaçoso e confortável com cinco lugares. Com assentos integrados e confortáveis.',
  title_design: 'Design renovado e esportivo',
  text_design: 'O BYD SONG PRO apresenta um novo design inspirado em dragões, com detalhes esportivos, difusores de ar em ambos os lados do para-choque dianteiro, conferindo ao veículo uma aparência mais arrojada e agressiva.',
  titles_feature: ['Explore e faça viagens', 'Bateria Blade BYD'],
  texts_feature: ['O rebatimento dos bancos e o grande porta-malas, somados à autonomia e eficiência do carro, permite a flexibilidade e versatilidade para as mais diversas viagens e aventuras.', 'A BYD é pioneira na indústria de baterias há mais de 28 anos. A mais recente e revolucionária Bateria Blade passou por uma série de testes extremos em condições rigorosas, tornando-a uma das mais seguras do mundo.'],
  titles_detail: ['Chave Digital NFC', 'Vehicle-to-Load (VTOL)', 'Porta-malas', 'Tela Suspensa Rotativa de 12,8 Polegadas'],
  texts_detail: ['O BYD SONG PRO pode ser equipado com uma chave digital em dispositivos móveis, permitindo desbloquear, trancar e ligar o veículo através de seu Android, iPhone ou Apple Watch, atendendo de forma abrangente os principais grupos de usuários de smartphones.', 'Foca em transformar o veículo elétrico em um robusto banco de energia móvel, permitindo que os usuários acessem a eletricidade armazenada sempre e onde for necessário.', 'Banco traseiro bipartido e ambos podem ser totalmente rebatidos, ficando nivelados com o porta-malas, atendendo a diversas necessidades e cenários.', 'A tela pode ser usada nas orientações horizontal e vertical. Pode ser dividida, e permite o uso simultâneo de diferentes aplicações, como software de chat à esquerda e navegação à direita, exibindo mais informações e aumentando a segurança durante a condução.'],
  title_penultimate: null,
  text_penultimate: null,
  titles_color: ['Azul', 'Cinza', 'Branco'],
  hexs_color: ['conic-gradient(from 249deg at 88.8% 29.26%, #A3CCF1 0deg, #1990D2 30.600000321865082deg, #044CA1 323.99999141693115deg, #0A1737 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #DEDEDE 0deg, #A3A3A3 32.40000128746033deg, #6A6A6A 323.99999141693115deg, #454545 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #EDEDED 0deg, #D4D4D4 23.399999141693115deg, #BFBFBF 309.1741132736206deg, #9E9E9E 360deg)']
  },
  {
  model: 'hybrid',
  name: 'king',
  new: true,
  soon: false,
  family: 2,
  order_family: 1,
  qty_imgs: 8,
  title_intro: 'BYD KING',
  text_intro: 'O NOVO REI DAS RUAS',
  titles_info: ['18,3 kW/h', '0,50 MJ/km', '235 cv', 'DM-I'],
  texts_info: ['Bateria Blade', 'Eficiência', 'Potência Combinada', 'Tecnologia Híbrido Plug-In'],
  title_overview: 'Elegante, confiável e eficiente',
  text_overview: 'BYD KING leva você mais longe, graças ao seu baixo consumo de combustível, podendo atingir 16,8 km/l na cidade (motor à combustão) e o equivalente a 44,2 km/l no modo elétrico. Com isso, chega a uma autonomia combinada de 1175 km (NEDC), que permitirá que você não se preocupe com a distância da viagem.',
  title_design: 'Design sofisticado e inovador',
  text_design: 'A frente é decorada com sutis formas geométricas poligonais e faixas cromadas, criando um estilo único e excepcional. Sua silhueta moderna prioriza seu espaço interno e de porta-malas ampliando conforto e espaço interno.',
  titles_feature: ['Tecnologia DM-i Híbrida Plug-In', 'TECNOLOGIAS INTELIGENTES'],
  texts_feature: ['Equipado com o sistema híbrido elétrico EHS, o BYD KING proporciona uma direção suave e potente. Podendo alternar entre modo 100% elétrico, ou híbrido, utilizando combustão e eletricidade.', 'A tecnologia BYD DM-i (Dual Mode) representa uma evolução revolucionária na tecnologia híbrida plug-in inteligente (PHEV). Desenvolvido exclusivamente pela BYD, traz uma infinidade de benefícios, incluindo eficiência energética, baixo consumo de combustível, desempenho de direção e conforto. Este sistema inovador centra-se na eficiência, adaptando-se sem esforço a diferentes cenários de condução.'],
  titles_detail: ['Console Central Moderno', 'Tecnologia Incomparável', 'Sistema elétrico inovador (EHS)', 'Motor híbrido de alta eficiência'],
  texts_detail: ['Moderno console central com freio de estacionamento elétrico, dispondo de diferentes modos de condução para se adaptar a qualquer tipo de viagem.', 'Exclusiva tela giratória de 12,8 polegadas complementa também o BYD KING com suporte para Apple Carplay e Android Auto proporcionando uma integração para uma conectividade perfeita.', 'Prioriza o uso de acionamento elétrico, com velocidade variável contínua eliminando o choque e desgaste causados ??pela mudança de marcha.', 'A maior eficiência térmica (43%) equilibra perfeitamente economia e autonomia, melhorando assim seu desempenho e performance durante toda sua condução.'],
  title_penultimate: null,
  text_penultimate: null,
  titles_color: ['Preto', 'Cinza', 'Branco'],
  hexs_color: ['conic-gradient(from 249deg at 88.8% 29.26%, #949494 0deg, #111 30.600000321865082deg, #2A2A2A 323.99999141693115deg, #000 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #95999D 0deg, #71787E 28.799999356269836deg, #424549 300.59999227523804deg, #212325 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #EDEDED 0deg, #D4D4D4 23.399999141693115deg, #BFBFBF 309.1741132736206deg, #9E9E9E 360deg)']
  },
  {
  model: 'hybrid',
  name: 'song plus',
  new: false,
  soon: false,
  family: 3,
  order_family: 2,
  qty_imgs: 16,
  title_intro: 'BYD SONG PLUS',
  text_intro: 'HÍBRIDO',
  titles_info: ['574 Litros', '236cv', '8.3s', 'Plug-in'],
  texts_info: ['Porta-malas', 'Potência Combinada', '0-100 km/h', 'Sistema Híbrido'],
  title_overview: 'BEM-ESTAR E COMODIDADE',
  text_overview: 'O modelo SongPLUS DM-i é um SUV médio que garante conforto aos passageiros. São 4,70 m de comprimento, 1,89 m de largura, 1,68 m de altura e entre-eixos de 2,76 m. Espaço amplo do porta-malas que pode chegar a 1.447 litros.',
  title_design: 'MODELO HÍBRIDO PLUG-IN',
  text_design: 'O BYD Song Plus é equipado com o mais recente sistema Dual-Mode da BYD que integra motor à combustão e bateria, em consideração de dinâmica, economia, NVH e outros desempenhos importantes.',
  titles_feature: ['CONTROLE TOTAL', 'PRAZER EM DIRIGIR'],
  texts_feature: ['Central multimídia touch com painel 100% HD digital personalizável com 12.3 polegadas • Volante multifuncional com controles de áudio e tecnologias semiautônomas • Faróis inteligentes em Full LED • Lanternas traseiras em LED • Luzes de direção dianteiras e traseiras com funcionamento dinâmico • Retrovisores externos com ajuste elétrico, rebatíveis e com desembaçador elétrico.', 'Super Híbrido Plug-in DM-i • Modo de direção - EV (elétrico) ou HEV (híbrido) • Escolha entre três estilos de direção (Eco, Normal e Sport) • Embreagem de acionamento direto (o mais próximo da experiência de dirigir um carro 100% elétrico).'],
  titles_detail: ['AR CONDICIONADO DIGITAL', 'BEM-ESTAR', 'TETO SOLAR PANORÂMICO', 'CONFORTO E SOFISTICAÇÃO'],
  texts_detail: ['Ar condicionado com "dual zone" • Saída de ventilação traseira com entradas USB.', 'Ajuste elétrico dos bancos dianteiros e dotados de aquecimento e ventilação • Sistema de Som Premium com assinatura Dirac® • Ambient Light com 31 opções de cores • Carregamento de celular por indução (Wireless).', 'Teto solar panorâmico com abertura e fechamento elétrico.', 'Acabamento do painel e portas Premium • Bancos revestidos de material Premium e sustentável • Freio de mão eletrônico com Auto-Hold • Abertura automática da tampa do porta-malas com sensor de presença.'],
  title_penultimate: null,
  text_penultimate: null,
  titles_color: ['Dome Blue', 'Delan Black', 'Time Grey', 'Snow White'],
  hexs_color: ['conic-gradient(from 249deg at 88.8% 29.26%, #A3CCF1 0deg, #1990D2 30.600000321865082deg, #044CA1 323.99999141693115deg, #0A1737 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #949494 0deg, #111 30.600000321865082deg, #2A2A2A 323.99999141693115deg, #000 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #DEDEDE 0deg, #A3A3A3 32.40000128746033deg, #6A6A6A 323.99999141693115deg, #454545 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #EDEDED 0deg, #D4D4D4 23.399999141693115deg, #BFBFBF 309.1741132736206deg, #9E9E9E 360deg)']
  },
  {
  model: 'hybrid',
  name: 'shark',
  new: true,
  soon: false,
  family: 6,
  order_family: 1,
  qty_imgs: 7,
  title_intro: 'BYD SHARK',
  text_intro: 'Potente como o agro',
  titles_info: ['5.7s', '100 Km', '840 Km', 'DMO'],
  texts_info: ['0-100 km/h', 'Autonomia Elétrico', 'Autonomia Combinada', 'Modo duplo off road/híbrido plug-in'],
  title_overview: 'Tecnologia de ponta com poder selvagem',
  text_overview: 'Liderado pelo Diretor Global de Design da BYD, Wolfgang Egger, o design é inspirado na agilidade e domínio dos tubarões',
  title_design: 'Mega símbolo dos mares',
  text_design: 'Face frontal integrada e agressiva. A gama completa apresenta iluminação de contorno LED e o grande logotipo tridimensional suspenso, realçando ainda mais a identidade robusta, distinta e com caráter.',
  titles_feature: ['Uma cabine mais personalizada, prática e inteligente', 'Plataforma super híbrida plug-in DMO-Off-road (Pickup)'],
  texts_feature: ['Criado pela diretora de design de interiores da BYD, Michele Jauch Paganetti, o interior combina a funcionalidade prática dos esportes ao ar livre com modernidade, estética, design e robustez.', 'Superpotência com tração elétrica inteligente nas quatro rodas, acompanha uma enorme capacidade para viagens diárias pela cidade e condução off-road.'],
  titles_detail: ['Sistema de cabine inteligente BYD', 'TELA MULTIMÍDIA GIRATÓRIA', 'Um interior espaçoso, desfrute do máximo conforto e conveniência', 'Alcance ultra longo - funciona com gasolina e eletricidade'],
  texts_detail: ['Na BYD Shark, o interior foi feito com materiais premium pensados no conforto e versatilidade ao usar sistemas inteligentes e de precisão.', 'Equipado com tela multimídia giratória de 12,8 polegadas e painel de instrumentos LCD de 10,25 polegadas. O sistema é compatível com Apple CarPlay e Android Auto.', 'Todo o veículo é revestido com materiais macios, aumentando a sensação geral de requinte. Até 10 espaços de armazenamento convenientes.', 'A arquitetura superhíbrida prioriza a propulsão do motor elétrico em até 80% do total de viagens, resultando em uma condução excepcionalmente suave. A suspensão independente de garfo BYD Shark dianteira e traseira equilibra melhor manuseio e conforto.'],
  title_penultimate: null,
  text_penultimate: null,
  titles_color: ['Azul', 'Preto', 'Branco'],
  hexs_color: ['conic-gradient(from 249deg at 88.8% 29.26%, #3A6CB6 0deg, #1B417C 80.99999785423279deg, #1B3C70 307.8000068664551deg, #162234 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #949494 0deg, #111 30.600000321865082deg, #2A2A2A 323.99999141693115deg, #000 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #EDEDED 0deg, #D4D4D4 23.399999141693115deg, #BFBFBF 309.1741132736206deg, #9E9E9E 360deg)']
  },
  {
  model: 'eletric',
  name: 'tan',
  new: false,
  soon: false,
  family: 7,
  order_family: 1,
  qty_imgs: 7,
  title_intro: null,
  text_intro: null,
  titles_info: [],
  texts_info: [],
  title_overview: null,
  text_overview: null,
  title_design: null,
  text_design: null,
  titles_feature: [],
  texts_feature: [],
  titles_detail: [],
  texts_detail: [],
  title_penultimate: null,
  text_penultimate: null,
  titles_color: [],
  hexs_color: []
  },
  {
  model: 'eletric',
  name: 'yuan pro',
  new: true,
  soon: false,
  family: 4,
  order_family: 1,
  qty_imgs: 0,
  title_intro: "BYD YUAN PRO",
  text_intro: "SEU PRIMEIRO SUV ELÉTRICO",
  titles_info: ['320 km', '7.9s', '177 cv', '12.8"'],
  texts_info: ['Autonomia', '0-100 km/h', 'Potência', 'Display Multimídia'],
  title_overview: 'Dimensões que surpreendem',
  text_overview: 'O veículo possui uma aparência mais larga, conferindo uma presença visual imponente. Um SUV espaçoso e confortável com cinco lugares.',
  title_design: "Atraente, seguro e fácil de dirigir",
  text_design: 'O BYD Yuan Pro integra uma aparência atraente, direção confortável e segurança em um veículo moderno e 100% elétrico.',
  titles_feature: ['Super seguro', 'Design da dinastia byd'],
  texts_feature: ['A BYD é pioneira na indústria de baterias há mais de 28 anos. A mais recente e revolucionária Bateria Blade passou por uma série de testes extremos em condições rigorosas, tornando-a uma das mais seguras do mundo.', 'O BYD Yuan Pro combina a sofisticação da linha Dinastia com tecnologia de ponta, oferecendo um design moderno, segurança avançada e estilo inconfundível. Cada detalhe foi pensado para proporcionar uma experiência de condução elegante e segura, redefinindo os padrões de qualidade e inovação.'],
  titles_detail: ['Iluminação traseira Led', 'Cabine ERGONÔMICA', 'Recarga inteligente', 'Amplo espaço'],
  texts_detail: ['É fácil distinguir mesmo ao dirigir à noite', 'O design do assento é ergonômico, o que traz muito conforto e sensação de segurança aos passageiros.', 'O telefone pode ser carregado sem fio por indução', 'Muito mais espaçoso do que os veículos da sua categoria'],
  title_penultimate: 'e-Platform 3.0',
  text_penultimate: 'O Yuan Pro também usa a e-Platform 3.0, especialmente desenvolvida pela BYD para veículos puramente elétricos. A plataforma busca melhorar a segurança e a autonomia, bem como garantir uma experiência de condução inteligente, criando um veículo mais eficiente e seguro.',
  titles_color: ['Branco', 'Cinza', 'Azul Escuro', 'Verde Claro', 'Rosa Claro'],
  hexs_color: ['conic-gradient(from 249deg at 88.8% 29.26%, #ECECEC 0deg, #E1E1E2 23.399999141693115deg, #E4E5E7 309.1741132736206deg, #BABDC3 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #95999D 0deg, #71787E 28.799999356269836deg, #424549 300.59999227523804deg, #212325 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #69ADBC 0deg, #184D6C 23.399999141693115deg, #13283C 309.1741132736206deg, #0B0F1E 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #E2E5CF 0deg, #D7DDC5 30.600000321865082deg, #B6BEA7 323.99999141693115deg, #909E84 360deg)', 'conic-gradient(from 249deg at 88.8% 29.26%, #E6D8DD 0deg, #D0BFC6 19.79999989271164deg, #B69EA8 300.59999227523804deg, #806872 360deg)']
  },
]
